<template>
  <section v-loading="loading" class="page-container">
    <div class="page-section config-panel">
      <el-tabs value="commonConfigTab">
        <el-tab-pane label="通用配置" name="commonConfigTab">
          <el-form label-width="160px">
            <el-form-item label="网站名称">
              <el-input v-model="config.siteTitle" type="text" placeholder="网站名称" />
            </el-form-item>

            <el-form-item label="网站描述">
              <el-input
                v-model="config.siteDescription"
                type="textarea"
                autosize
                placeholder="网站描述"
              />
            </el-form-item>

            <el-form-item label="网站关键字">
              <el-select
                v-model="config.siteKeywords"
                style="width: 100%"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="网站关键字"
              />
            </el-form-item>

            <el-form-item label="网站公告">
              <el-input
                v-model="config.siteNotification"
                type="textarea"
                autosize
                placeholder="网站公告（支持输入HTML）"
              />
            </el-form-item>

            <el-form-item label="公众网址">
              <el-input v-model="config.siteUrl" type="text" placeholder="公众网址" />
            </el-form-item>

            <el-form-item label="推荐标签">
              <el-select
                v-model="config.recommendTags"
                style="width: 100%"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="推荐标签"
              />
            </el-form-item>

            <el-form-item label="默认节点">
              <el-select
                v-model="config.defaultNodeId"
                style="width: 100%"
                placeholder="发帖默认节点"
              >
                <el-option
                  v-for="node in nodes"
                  :key="node.id"
                  :label="node.name"
                  :value="node.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="网站关于">
              <el-input
                v-model="config.siteAbout"
                type="textarea"
                autosize
                placeholder="网站关于（支持输入HTML和Markdown）"
              />
            </el-form-item>

            <el-form-item label="站外链接跳转页面">
              <el-tooltip content="在跳转前需手动确认是否前往该站外链接" placement="top">
                <el-switch v-model="config.urlRedirect" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="导航配置" name="navConfigTab" class="nav-panel">
          <draggable v-model="config.siteNavs" draggable=".nav" handle=".nav-sort-btn" class="navs">
            <div v-for="(nav, index) in config.siteNavs" :key="index" class="nav">
              <el-row :gutter="20">
                <el-col :span="1">
                  <i class="iconfont icon-sort nav-sort-btn" />
                </el-col>
                <el-col :span="4">
                  <el-select v-model="nav.type" placeholder="请选择">
                    <el-option key="Header" label="Header" value="0"> </el-option>
                    <el-option key="Footer" label="Footer" value="1"> </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-input v-model="nav.title" type="text" size="small" placeholder="标题" />
                </el-col>
                <el-col :span="11">
                  <el-input v-model="nav.url" type="text" size="small" placeholder="链接" />
                </el-col>
                <el-col :span="2">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    size="small"
                    @click="delNav(index)"
                  />
                </el-col>
              </el-row>
            </div>
          </draggable>
          <div class="add-nav">
            <el-tooltip class="item" effect="dark" content="点击按钮添加导航" placement="top">
              <el-button type="primary" icon="el-icon-plus" circle @click="addNav" />
            </el-tooltip>
          </div>
        </el-tab-pane>
        <el-tab-pane label="登录配置" name="loginConfigTab">
          <!-- <template v-if="config.loginMethod">
              <el-form-item label="登录方式">
                <el-checkbox v-model="config.loginMethod.password"> 密码登录 </el-checkbox>
                <el-checkbox v-model="config.loginMethod.qq"> QQ登录 </el-checkbox>
                <el-checkbox v-model="config.loginMethod.github"> Github登录 </el-checkbox>
                <el-checkbox v-model="config.loginMethod.osc"> OSChina登录 </el-checkbox>
              </el-form-item>
            </template> -->
          <el-form label-width="160px">
            <el-form-item label="密码登录">
              <el-tooltip content="密码登录" placement="top">
                <el-switch v-model="config.loginMethod.password" />
              </el-tooltip>
            </el-form-item>
            <el-form-item label="QQ登录">
              <el-tooltip content="QQ登录" placement="top">
                <el-switch v-model="config.loginMethod.qq" />
              </el-tooltip>
            </el-form-item>
            <el-form-item v-if="config.loginMethod.qq" label="AppKey">
              <el-input v-model="config.qqApp.id" type="text" placeholder="AppKey" />
            </el-form-item>
            <el-form-item v-if="config.loginMethod.qq" label="AppSecret">
              <el-input v-model="config.qqApp.secret" type="text" placeholder="AppSecret" />
            </el-form-item>
            <el-form-item label="Gitee登录">
              <el-tooltip content="Gitee登录" placement="top">
                <el-switch v-model="config.loginMethod.gitee" />
              </el-tooltip>
            </el-form-item>
            <el-form-item v-if="config.loginMethod.gitee" label="ClientID">
              <el-input v-model="config.giteeClient.id" type="text" placeholder="ClientID" />
            </el-form-item>
            <el-form-item v-if="config.loginMethod.gitee" label="ClientSecret">
              <el-input
                v-model="config.giteeClient.secret"
                type="text"
                placeholder="ClientSecret"
              />
            </el-form-item>
            <el-form-item label="Github登录">
              <el-tooltip content="Github登录" placement="top">
                <el-switch v-model="config.loginMethod.github" />
              </el-tooltip>
            </el-form-item>
            <el-form-item v-if="config.loginMethod.github" label="ClientID">
              <el-input v-model="config.githubClient.id" type="text" placeholder="ClientID" />
            </el-form-item>
            <el-form-item v-if="config.loginMethod.github" label="ClientSecret">
              <el-input
                v-model="config.githubClient.secret"
                type="text"
                placeholder="ClientSecret"
              />
            </el-form-item>
            <el-form-item label="OSChina登录">
              <el-tooltip content="OSChina登录" placement="top">
                <el-switch v-model="config.loginMethod.osc" />
              </el-tooltip>
            </el-form-item>
            <el-form-item v-if="config.loginMethod.osc" label="ClientID">
              <el-input v-model="config.osChinaClient.id" type="text" placeholder="ClientID" />
            </el-form-item>
            <el-form-item v-if="config.loginMethod.osc" label="ClientSecret">
              <el-input
                v-model="config.osChinaClient.secret"
                type="text"
                placeholder="ClientSecret"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Session配置" name="sessionConfigTab">
          <el-form label-width="160px">
            <el-form-item label="登录Token有效期(天)">
              <el-input-number
                v-model="config.tokenExpireDays"
                :min="1"
                type="text"
                placeholder="登录Token有效期(天)"
              />
            </el-form-item>
            <el-form-item label="验证Token有效期(时)">
              <el-input-number
                v-model="config.codeVerifyExpireHour"
                :min="1"
                type="text"
                placeholder="验证Token有效期(时)"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="config.scoreConfig" label="积分配置" name="scoreConfigTab">
          <el-form label-width="160px">
            <el-form-item label="发帖积分">
              <el-input-number
                v-model="config.scoreConfig.postTopicScore"
                :min="1"
                type="text"
                placeholder="发帖获得积分"
              />
            </el-form-item>
            <el-form-item label="跟帖积分">
              <el-input-number
                v-model="config.scoreConfig.postCommentScore"
                :min="1"
                type="text"
                placeholder="跟帖获得积分"
              />
            </el-form-item>
            <el-form-item label="签到积分">
              <el-input-number
                v-model="config.scoreConfig.checkInScore"
                :min="1"
                type="text"
                placeholder="签到获得积分"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="反作弊" name="spamConfigTab">
          <el-form label-width="160px">
            <el-form-item label="动态验证码">
              <el-tooltip content="发动态时是否开启验证码校验" placement="top">
                <el-switch v-model="config.topicCaptcha" />
              </el-tooltip>
            </el-form-item>

            <el-form-item label="邮箱验证后发动态">
              <el-tooltip content="需要验证邮箱后才能发动态" placement="top">
                <el-switch v-model="config.createTopicEmailVerified" />
              </el-tooltip>
            </el-form-item>

            <!-- <el-form-item label="邮箱验证后发表文章">
              <el-tooltip content="需要验证邮箱后才能发表文章" placement="top">
                <el-switch v-model="config.createArticleEmailVerified" />
              </el-tooltip>
            </el-form-item> -->

            <el-form-item label="邮箱验证后评论">
              <el-tooltip content="需要验证邮箱后才能发表评论" placement="top">
                <el-switch v-model="config.createCommentEmailVerified" />
              </el-tooltip>
            </el-form-item>

            <el-form-item label="用户观察期(秒)">
              <el-tooltip
                content="观察期内用户无法发表动态等内容，设置为 0 表示无观察期。"
                placement="top"
              >
                <el-slider
                  v-model="config.userObserveSeconds"
                  :min="0"
                  :max="86400"
                  :step="60"
                  show-input
                ></el-slider>
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="ES配置" name="esConfigTab">
          <el-form label-width="160px">
            <el-form-item label="Url">
              <el-input v-model="config.es.url" type="text" placeholder="http://localhost:9000" />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="SMTP配置" name="smtpConfigTab">
          <el-form label-width="160px">
            <el-form-item label="Host">
              <el-input v-model="config.smtp.host" type="text" placeholder="smtp.aliyun.com" />
            </el-form-item>
            <el-form-item label="Port">
              <el-input v-model="config.smtp.port" type="text" placeholder="25" />
            </el-form-item>
            <el-form-item label="UserName">
              <el-input v-model="config.smtp.username" type="text" placeholder="username" />
            </el-form-item>
            <el-form-item label="PassWord">
              <el-input
                v-model="config.smtp.password"
                type="text"
                placeholder="password"
                show-password
              />
            </el-form-item>
            <el-form-item label="SSL">
              <el-tooltip content="SSL" placement="top">
                <el-switch v-model="config.smtp.ssl" />
              </el-tooltip>
            </el-form-item>
            <el-form-item label="邮件模板">
              <el-input
                v-model="config.emailTemplate"
                type="textarea"
                autosize
                placeholder="邮件模板（支持输入HTML）"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="内容审核" name="censorConfigTab">
          <el-form label-width="160px">
            <el-form-item label="人工审核">
              <el-tooltip content="发布动态后是否开启审核" placement="top">
                <el-switch v-model="config.topicPending" />
              </el-tooltip>
            </el-form-item>
            <el-form-item label="智能审核">
              <el-tooltip content="发布动态后是否智能审核" placement="top">
                <el-switch v-model="config.aiCensor" />
              </el-tooltip>
            </el-form-item>
            <el-form-item v-if="config.aiCensor" label="第三方API">
              <el-input
                v-model="config.externalUrl"
                type="text"
                placeholder="第三方智能审核接口地址(例如http://127.0.0.1:8090)"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <div style="text-align: right">
        <el-button :loading="loading" type="primary" @click="save"> 保存配置 </el-button>
      </div>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: { draggable },
  data() {
    return {
      config: {},
      loading: false,
      autocompleteTags: [],
      autocompleteTagLoading: false,
      nodes: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        this.config = await this.axios.get("/api/admin/sys-config/all");
        this.nodes = await this.axios.get("/api/admin/topic-node/nodes");
        console.log(this.config);
      } catch (err) {
        this.$notify.error({ title: "错误", message: err.message });
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.loading = true;
      try {
        await this.axios.form("/api/admin/sys-config/save", {
          config: JSON.stringify(this.config),
        });
        this.$message({ message: "提交成功", type: "success" });
        this.load();
      } catch (err) {
        this.$notify.error({ title: "错误", message: err.message });
      } finally {
        this.loading = false;
      }
    },
    addNav() {
      if (!this.config.siteNavs) {
        this.config.siteNavs = [];
      }
      this.config.siteNavs.push({
        title: "",
        url: "",
      });
    },
    delNav(index) {
      if (!this.config.siteNavs) {
        return;
      }
      this.config.siteNavs.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.config-panel {
  // margin: 20px;
  padding: 10px;
}

.config {
  padding: 10px 0;
}

.nav-panel {
  .navs {
    border: 1px solid #ddd;
    border-radius: 5px;

    .nav {
      padding: 5px 5px;
      margin: 0;

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      .nav-sort-btn {
        font-size: 21px;
        font-weight: 700;
        cursor: pointer;
        float: right;
      }
    }
  }

  .add-nav {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
